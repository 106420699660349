import { useQuery } from '@tanstack/react-query';
import { fetchEntityPermissionsApi } from '../helpers/api/permissionsApi';
import { EntityPermission } from '../types';
import { ActiveSplits, useReleaseToggle } from '../helpers/releaseToggle';

export const PERMISSIONS_KEYS = {
  ENTITY_PERMISSIONS: 'ENTITY_PERMISSIONS',
};

export const useEntityPermission = (entityId: string) => {
  const { data: isNewService, isLoading } = useReleaseToggle(
    ActiveSplits.accessServiceRollout,
  );
  return useQuery<EntityPermission>(
    [PERMISSIONS_KEYS.ENTITY_PERMISSIONS, entityId],
    () => fetchEntityPermissionsApi({ entityId, isNewService }),
    { enabled: !!entityId && !isLoading, staleTime: Infinity },
  );
};
