import React from 'react';
import {
  Button,
  ModalBase,
  Card,
  token,
  Form,
  Input,
  Divider,
} from '@bynder/design-system';
import { Translate, useTranslate } from '@bynder/localization';
import PrimaryButton from '../shared/PrimaryButton';
import { TemplatesEnum } from '../../constants/templates/constants';
import styled from 'styled-components';
import whiteTemplate from '../../assets/whiteTemplate.png';
import homePageTemplate from '../../assets/homePageTemplate.svg';
import gtmTemplate from '../../assets/gtmTemplate.svg';
import plTemplate from '../../assets/plTemplate.svg';
import hpTemplate from '../../assets/hpTemplate.svg';
import bpTemplate from '../../assets/bpTemplate.svg';
import guidePageTemplate from '../../assets/guidePageTemplate.svg';
import { useLandingPageMutations } from '../../mutations/landingPage';
import { useInspectorStore } from '../../stores/inspectorStore';
import { GUIDE_TITLE_MAX_LENGTH } from '../../constants/guides';
import MaxCharacterCounter from '../shared/MaxCharacterCounter';
import { EntityEnum } from './NewCreateModal';
import { usePageMutations } from '../../mutations/pages';
import { generateUUID } from '../../helpers/utils';

interface CreateModalProps {
  entityType: EntityEnum;
  onCancel: () => void;
  chapterId?: string;
}

const PageTemplateModal: React.FC<CreateModalProps> = ({
  entityType,
  onCancel,
  chapterId,
}) => {
  const { translate } = useTranslate();
  const { createLandingPage, addPageTemplate } = useLandingPageMutations();
  const { addPage, addGuidePageTemplate } = usePageMutations();
  const activeTemplate = useInspectorStore(s => s.activeTemplate);
  const setActiveTemplate = useInspectorStore(s => s.setTemplate);
  const [title, setTitle] = React.useState('');
  const isLandingPage = entityType === EntityEnum.LANDING_PAGE;

  const handleAddTemplate = () => {
    if (activeTemplate === TemplatesEnum.WHITE_TEMPLATE) {
      return isLandingPage
        ? createLandingPage.mutate({ guideTitle: title })
        : addPage.mutate({
            pageTitle: title,
            chapterId,
            newPageId: generateUUID(),
          });
    }

    return isLandingPage
      ? addPageTemplate.mutate({
          pageTitle: title,
          pageTemplate: activeTemplate,
        })
      : addGuidePageTemplate.mutate({
          pageTitle: title,
          pageTemplate: activeTemplate,
          chapterId,
        });
  };

  const PageTemplateCard = ({
    template,
    templateImg,
    templateName,
  }: {
    template: TemplatesEnum;
    templateImg: string;
    templateName: string;
  }) => (
    <Card
      variant="clean"
      onClick={() => setActiveTemplate(template)}
      onSelect={() => setActiveTemplate(template)}
      isSelected={activeTemplate === template}
    >
      <Card.Frame height="fixed">
        <Card.Images>
          <Card.Img src={templateImg} />
        </Card.Images>
      </Card.Frame>
      <Card.Header title={templateName} />
    </Card>
  );

  return (
    <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel} size="medium">
      <ModalBase.Header
        title={
          isLandingPage
            ? translate('CREATE_MODAL.NEW_PAGE_HEADER_TEXT')
            : translate('CREATE_MODAL.NEW_GUIDE_PAGE_HEADER_TEXT')
        }
      />
      <ModalBase.Content>
        <Form.Group>
          <Form.Label htmlFor="modalInput">
            <Translate id="PAGE_TEMPLATE_MODAL.INPUT_LABEL" />
          </Form.Label>
          <Input
            id="modalInput"
            placeholder={translate('PAGE_TEMPLATE_MODAL.INPUT_PLACEHOLDER')}
            value={title}
            onChange={setTitle}
            data-testid="modalInput"
            maxLength={GUIDE_TITLE_MAX_LENGTH}
            iconRight={
              <MaxCharacterCounter
                value={title}
                maxValue={GUIDE_TITLE_MAX_LENGTH}
              />
            }
          />
        </Form.Group>
        <Divider />
        <ModalContentTile>
          <Translate id="PAGE_TEMPLATE_MODAL.SELECT_TITLE" />
        </ModalContentTile>
        <Grid>
          <PageTemplateCard
            template={TemplatesEnum.WHITE_TEMPLATE}
            templateImg={whiteTemplate}
            templateName={translate('PAGE_TEMPLATE_MODAL.BLANK_TEMPLATE')}
          />
          {isLandingPage ? (
            <>
              <PageTemplateCard
                template={TemplatesEnum.LANDING_PAGE_TEMPLATE}
                templateImg={homePageTemplate}
                templateName={translate('PAGE_TEMPLATE_MODAL.HP_TEMPLATE_1')}
              />

              <PageTemplateCard
                template={TemplatesEnum.HOMEPAGE_TEMPLATE}
                templateImg={hpTemplate}
                templateName={translate('PAGE_TEMPLATE_MODAL.HP_TEMPLATE_2')}
              />

              <PageTemplateCard
                template={TemplatesEnum.GTM_TEMPLATE}
                templateImg={gtmTemplate}
                templateName={translate('PAGE_TEMPLATE_MODAL.GTM_TEMPLATE')}
              />

              <PageTemplateCard
                template={TemplatesEnum.PL_TEMPLATE}
                templateImg={plTemplate}
                templateName={translate('PAGE_TEMPLATE_MODAL.PL_TEMPLATE')}
              />

              <PageTemplateCard
                template={TemplatesEnum.BRAND_PORTAL_TEMPLATE}
                templateImg={bpTemplate}
                templateName={translate('PAGE_TEMPLATE_MODAL.BRAND_PORTAL')}
              />
            </>
          ) : (
            <PageTemplateCard
              template={TemplatesEnum.GUIDE_PAGE_TEMPLATE}
              templateImg={guidePageTemplate}
              templateName={translate('PAGE_TEMPLATE_MODAL.BRAND_PORTAL')}
            />
          )}
        </Grid>
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <PrimaryButton
            data-testid="submitButton"
            onClick={handleAddTemplate}
            isDisabled={!title}
          >
            <Translate id="CREATE_MODAL.DEFAULT_BUTTON_LABEL" />
          </PrimaryButton>
        }
        actionSecondary={
          <Button variant="secondary" onClick={onCancel}>
            <Translate id="CREATE_MODAL.CANCEL_BUTTON_LABEL" />
          </Button>
        }
      />
    </ModalBase>
  );
};

export default PageTemplateModal;

const ModalContentTile = styled.div`
  margin-top: ${token.spacing6};
`;

const Grid = styled.div`
  margin-top: ${token.spacing6};
  display: grid;
  grid-template-columns: repeat(3, 250px);
  gap: ${token.spacing6};
  align-items: flex-start;
`;
