import React, { Children } from 'react';
import {
  Button,
  ModalBase,
  Card,
  token,
  Tooltip,
  VerticalNav,
} from '@bynder/design-system';
import { Translate, useTranslate } from '@bynder/localization';
import PrimaryButton from '../shared/PrimaryButton';
import { useInspectorStore } from '../../stores/inspectorStore';
import { generateUUID } from '../../helpers/utils';
import { useSectionMutations } from '../../mutations/sections';
import { useActiveSection } from '../../queries/guides';
import { useIsEntityChanging } from '../../stores/socketsStore';
import { useGuideStore } from '../../stores/guideStore';
import { TemplatesEnum } from '../../constants/templates/constants';

import styled from 'styled-components';
import whiteTemplate from '../../assets/whiteTemplate.png';
import textTemplate from '../../assets/textTemplate.svg';
import cardTemplate from '../../assets/cardTemplate.svg';
import fourCardTemplate from '../../assets/fourCardTemplate.svg';
import sixCardTemplate from '../../assets/sixCardTemplate.svg';
import heroTemplate from '../../assets/heroTemplate.svg';
import heroWithImageTemplate from '../../assets/heroWithImageTemplate.svg';
import twoColumnFeaturedTemplate from '../../assets/twoColumnFeaturedTemplate.svg';
import footerWithImageTemplate from '../../assets/footerWithImageTemplate.svg';
import fiveCardNavigation from '../../assets/fiveCardNavigation.svg';
import eightCardNavigation from '../../assets/eightCardNavigation.svg';
import tenCardNavigation from '../../assets/tenCardNavigation.svg';
import twoColumnHeroTemplate from '../../assets/twoColumnHeroTemplate.svg';
import twoColumnRightHeroTemplate from '../../assets/twoColumnRightHeroTemplate.svg';
import twoColumnLeftHeroTemplate from '../../assets/twoColumnLeftHeroTemplate.svg';
import twoCardLargeTemplate from '../../assets/twoCardLargeTemplate.svg';
import twoColumnNavigationTemplate from '../../assets/twoColumnNavigationTemplate.svg';
import threeCardTemplate from '../../assets/threeCardTemplate.svg';
import twoColumnCardNavigationTemplate from '../../assets/twoColumnCardNavigationTemplate.svg';
import threeColumnCardNavigationTemplate from '../../assets/threeColumnCardNavigationTemplate.svg';
import darkLeftRightPageNavigation from '../../assets/darkLeftRightPageNavigation.svg';
import lightLeftRightPageNavigation from '../../assets/lightLeftRightPageNavigation.svg';
import darkUpDownPageNavigation from '../../assets/darkUpDownPageNavigation.svg';
import lighUpDownPageNavigation from '../../assets/lightUpDownPageNavigation.svg';
import darkSquarePagination from '../../assets/darkSquarePagination.svg';
import lightSquarePagination from '../../assets/lightSquarePagination.svg';
import darkRoundPagination from '../../assets/darkRoundPagination.svg';
import lightRoundPagination from '../../assets/lightRoundPagination.svg';
import footerText from '../../assets/footerText.svg';
import heroBanner from '../../assets/heroBanner.svg';
import heroBannerCards from '../../assets/heroBannerCards.svg';
import heroBannerButtons from '../../assets/heroBannerButtons.svg';
import heroBannerCardColumns from '../../assets/heroBannerCardColumns.svg';
import heroBannerCardColumns2 from '../../assets/heroBannerCardColumns2.svg';
import heroBannerButtonColumns from '../../assets/heroBannerButtonColumns.svg';

interface CreateModalProps {
  onCancel: () => void;
}

enum ActiveModalSection {
  ALL = 'All content',
  BANNER = 'Banner',
  BODY = 'Body',
  FOOTER = 'Footer',
  NAVIGATION = 'Navigation',
}

const SectionTemplateModal: React.FC<CreateModalProps> = ({ onCancel }) => {
  const activeSectionTemplate = useInspectorStore(s => s.activeTemplate);
  const setSectionTemplate = useInspectorStore(s => s.setTemplate);
  const activeGuideId = useGuideStore(s => s.activeGuideId);
  const { addSection, addSectionTemplate } = useSectionMutations();
  const isChangingEntity = useIsEntityChanging(activeGuideId);
  const { sectionIndex } = useActiveSection();
  const { translate } = useTranslate();
  const [activeModalSection, setActiveModalSection] = React.useState(
    ActiveModalSection.ALL,
  );

  const handleAddSectionTemplate = () => {
    const sectionId = generateUUID();
    if (activeSectionTemplate === TemplatesEnum.WHITE_TEMPLATE) {
      addSection.mutate({ sectionId, sectionPosition: sectionIndex + 1 });
    }

    addSectionTemplate.mutate({
      sectionId,
      sectionTemplate: activeSectionTemplate,
      sectionPosition: sectionIndex + 1,
    });
  };

  const SectionTemplateCard = ({
    sectionTemplate,
    templateImg,
  }: {
    sectionTemplate: TemplatesEnum;
    templateImg: string;
  }) => (
    <Card
      variant="clean"
      onClick={() => setSectionTemplate(sectionTemplate)}
      onSelect={() => setSectionTemplate(sectionTemplate)}
      isSelected={activeSectionTemplate === sectionTemplate}
    >
      <Card.Frame height="fixed">
        <Card.Images>
          <Card.Img src={templateImg} />
        </Card.Images>
      </Card.Frame>
    </Card>
  );

  const isAllSection = activeModalSection === ActiveModalSection.ALL;
  const isBannerSection = activeModalSection === ActiveModalSection.BANNER;
  const isBodySection = activeModalSection === ActiveModalSection.BODY;
  const isFooterSection = activeModalSection === ActiveModalSection.FOOTER;
  const isNavigationSection =
    activeModalSection === ActiveModalSection.NAVIGATION;

  const renderBannerSection = () => (
    <>
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.HERO_TEMPLATE}
        templateImg={heroTemplate}
      />

      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.HERO_BANNER_TEMPLATE}
        templateImg={heroBanner}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.HERO_BANNER_CARDS_TEMPLATE}
        templateImg={heroBannerCards}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.HERO_BANNER_BUTTONS_TEMPLATE}
        templateImg={heroBannerButtons}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.HERO_BANNER_CARD_COLUMNS_TEMPLATE}
        templateImg={heroBannerCardColumns}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.HERO_BANNER_CARD_COLUMNS_2_TEMPLATE}
        templateImg={heroBannerCardColumns2}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.HERO_BANNER_BUTTONS_COLUMNS_TEMPLATE}
        templateImg={heroBannerButtonColumns}
      />
    </>
  );

  const renderBodySection = () => (
    <>
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.HERO_WITH_IMAGE_TEMPLATE}
        templateImg={heroWithImageTemplate}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.FOOTER_WITH_IMAGE_TEMPLATE}
        templateImg={footerWithImageTemplate}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.TEXT_TEMPLATE}
        templateImg={textTemplate}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.TWO_COLUMN_FEATURED_TEMPLATE}
        templateImg={twoColumnFeaturedTemplate}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.TWO_COLUMN_HERO_TEMPLATE}
        templateImg={twoColumnHeroTemplate}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.TWO_COLUMN_RIGHT_HERO_TEMPLATE}
        templateImg={twoColumnRightHeroTemplate}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.TWO_COLUMN_LEFT_HERO_TEMPLATE}
        templateImg={twoColumnLeftHeroTemplate}
      />
    </>
  );

  const renderFooterSection = () => (
    <>
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.FOOTER_TEXT_TEMPLATE}
        templateImg={footerText}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.DARK_LEFT_RIGHT_PAGE_NAVIGATION_TEMPLATE}
        templateImg={darkLeftRightPageNavigation}
      />
      <SectionTemplateCard
        sectionTemplate={
          TemplatesEnum.LIGHT_LEFT_RIGHT_PAGE_NAVIGATION_TEMPLATE
        }
        templateImg={lightLeftRightPageNavigation}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.DARK_UP_DOWN_PAGE_NAVIGATION_TEMPLATE}
        templateImg={darkUpDownPageNavigation}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.LIGHT_UP_DOWN_PAGE_NAVIGATION_TEMPLATE}
        templateImg={lighUpDownPageNavigation}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.DARK_SQUARE_PAGINATION_TEMPLATE}
        templateImg={darkSquarePagination}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.LIGHT_SQUARE_PAGINATION_TEMPLATE}
        templateImg={lightSquarePagination}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.DARK_ROUND_PAGINATION_TEMPLATE}
        templateImg={darkRoundPagination}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.LIGHT_ROUND_PAGINATION_TEMPLATE}
        templateImg={lightRoundPagination}
      />
    </>
  );

  const renderNavigationSection = () => (
    <>
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.TWO_CARD_TEMPLATE}
        templateImg={cardTemplate}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.FOUR_CARD_TEMPLATE}
        templateImg={fourCardTemplate}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.FIVE_CARD_TEMPLATE}
        templateImg={fiveCardNavigation}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.SIX_CARD_TEMPLATE}
        templateImg={sixCardTemplate}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.EIGHT_CARD_TEMPLATE}
        templateImg={eightCardNavigation}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.TEN_CARD_TEMPLATE}
        templateImg={tenCardNavigation}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.TWO_CARD_LARGE_TEMPLATE}
        templateImg={twoCardLargeTemplate}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.TWO_COLUMN_NAVIGATION_TEMPLATE}
        templateImg={twoColumnNavigationTemplate}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.THREE_CARD_TEMPLATE}
        templateImg={threeCardTemplate}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.TWO_COLUMN_CARD_NAVIGATION_TEMPLATE}
        templateImg={twoColumnCardNavigationTemplate}
      />
      <SectionTemplateCard
        sectionTemplate={TemplatesEnum.THREE_COLUMN_CARD_NAVIGATION_TEMPLATE}
        templateImg={threeColumnCardNavigationTemplate}
      />
    </>
  );

  const bannerTemplatesLength = Children.count(
    renderBannerSection().props.children,
  );
  const bodyTemplatesLength = Children.count(
    renderBodySection().props.children,
  );
  const footerTemplatesLength = Children.count(
    renderFooterSection().props.children,
  );
  const navigationTemplatesLength = Children.count(
    renderNavigationSection().props.children,
  );
  const allTemplatesLength =
    bannerTemplatesLength +
    bodyTemplatesLength +
    footerTemplatesLength +
    navigationTemplatesLength;

  return (
    <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel} size="medium">
      <ModalBase.Header
        title={translate('SECTION_TEMPLATE_MODAL.HEADER_TITLE')}
      />
      <ModalBase.Sidebar>
        <SidebarContainer>
          <VerticalNav>
            <VerticalNav.Item
              isActive={isAllSection}
              onClick={() => setActiveModalSection(ActiveModalSection.ALL)}
              rightElements={[
                <VerticalNav.RightText>
                  {allTemplatesLength}
                </VerticalNav.RightText>,
              ]}
            >
              <Translate id="SECTION_TEMPLATE_MODAL.ALL_CONTENT_TITLE" />
            </VerticalNav.Item>
            <VerticalNav.Item
              isActive={isBannerSection}
              onClick={() => setActiveModalSection(ActiveModalSection.BANNER)}
              rightElements={[
                <VerticalNav.RightText>
                  {bannerTemplatesLength}
                </VerticalNav.RightText>,
              ]}
            >
              {translate('SECTION_TEMPLATE_MODAL.BANNER_TITLE')}
            </VerticalNav.Item>
            <VerticalNav.Item
              isActive={isBodySection}
              onClick={() => setActiveModalSection(ActiveModalSection.BODY)}
              rightElements={[
                <VerticalNav.RightText>
                  {bodyTemplatesLength}
                </VerticalNav.RightText>,
              ]}
            >
              {translate('SECTION_TEMPLATE_MODAL.BODY_TITLE')}
            </VerticalNav.Item>
            <VerticalNav.Item
              isActive={isNavigationSection}
              onClick={() =>
                setActiveModalSection(ActiveModalSection.NAVIGATION)
              }
              rightElements={[
                <VerticalNav.RightText>
                  {navigationTemplatesLength}
                </VerticalNav.RightText>,
              ]}
            >
              {translate('SECTION_TEMPLATE_MODAL.NAVIGATION_TITLE')}
            </VerticalNav.Item>
            <VerticalNav.Item
              isActive={isFooterSection}
              onClick={() => setActiveModalSection(ActiveModalSection.FOOTER)}
              rightElements={[
                <VerticalNav.RightText>
                  {footerTemplatesLength}
                </VerticalNav.RightText>,
              ]}
            >
              {translate('SECTION_TEMPLATE_MODAL.FOOTER_TITLE')}
            </VerticalNav.Item>
          </VerticalNav>
        </SidebarContainer>
      </ModalBase.Sidebar>
      <ModalBase.Content>
        <Grid>
          {isAllSection && (
            <>
              <Tooltip content={translate('WIDGET_MENU.ADD_SECTION_BOTTOM')}>
                <SectionTemplateCard
                  sectionTemplate={TemplatesEnum.WHITE_TEMPLATE}
                  templateImg={whiteTemplate}
                />
              </Tooltip>
              {renderBannerSection()}
              {renderBodySection()}
              {renderNavigationSection()}
              {renderFooterSection()}
            </>
          )}
          {isBannerSection && renderBannerSection()}
          {isBodySection && renderBodySection()}
          {isFooterSection && renderFooterSection()}
          {isNavigationSection && renderNavigationSection()}
        </Grid>
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <PrimaryButton
            isDisabled={isChangingEntity}
            data-testid="submitButton"
            onClick={handleAddSectionTemplate}
            isLoading={isChangingEntity}
          >
            <Translate id="CREATE_MODAL.DEFAULT_BUTTON_LABEL" />
          </PrimaryButton>
        }
        actionSecondary={
          <Button
            variant="secondary"
            onClick={onCancel}
            isDisabled={isChangingEntity}
          >
            <Translate id="CREATE_MODAL.CANCEL_BUTTON_LABEL" />
          </Button>
        }
      />
    </ModalBase>
  );
};

export default SectionTemplateModal;

const SidebarContainer = styled.div`
  height: 720px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 250px);
  gap: ${token.spacing6};
  align-items: flex-start;
`;
