import React from 'react';
import { Portal } from 'react-portal';
import { CollectionPicker } from '@bynder/collection-picker';
import CompactViewModal from './CompactViewModal';
import { CreateGroupModal } from './CreateGroupModal';
import CreateModal from './CreateModal';
import DeleteModal from './DeleteModal';
import WarningModal from './WarningModal';
import TableOfContentsModal from './TableOfContentsModal';
import CreateThemesetModal from './themesets/CreateThemesetModal';
import EditThemesetDetailsModal from './themesets/EditThemesetDetailsModal';
import { AccessModal } from './AccessModal';
import { ModalType } from '../../types';
import ImageWidgetCustomUrlModal from './ImageWidgetCustomUrlModal';
import { MovePageModal } from './MovePageModal';
import MaxGuideSizeModal from './MaxGuideSizeModal';
import NewCreateModal from './NewCreateModal';
import { useModalStore } from '../../stores/modalStore';
import SectionTemplateModal from './SectionTemplateModal';
import PageTemplateModal from './PageTemplateModal';
import HomepageModal from './HomepageModal';
import UnsetHomepageModal from './UnsetHomepageModal';

const MODAL_COMPONENTS: { [key in ModalType]: React.FC } = {
  CREATE_MODAL: CreateModal,
  NEW_CREATE_MODAL: NewCreateModal,
  CREATE_GROUP_MODAL: CreateGroupModal,
  DELETE_MODAL: DeleteModal,
  COMPACT_VIEW_MODAL: CompactViewModal,
  COLLECTIONS_MODAL: CollectionPicker,
  TABLE_OF_CONTENTS_MODAL: TableOfContentsModal,
  EDIT_THEMESET_DETAILS_MODAL: EditThemesetDetailsModal,
  CREATE_THEMESET_MODAL: CreateThemesetModal,
  WARNING_MODAL: WarningModal,
  ACCESS_MODAL: AccessModal,
  IMAGE_WIDGET_CUSTOM_URL: ImageWidgetCustomUrlModal,
  MOVE_PAGE_MODAL: MovePageModal,
  MAX_GUIDE_SIZE_MODAL: MaxGuideSizeModal,
  SECTION_TEMPLATE_MODAL: SectionTemplateModal,
  PAGE_TEMPLATE_MODAL: PageTemplateModal,
  HOMEPAGE_MODAL: HomepageModal,
  UNSET_HOMEPAGE_MODAL: UnsetHomepageModal,
};

const ModalContainer: React.FC = () => {
  const modal = useModalStore.use.modal();
  const closeModal = useModalStore.use.closeModal();

  if (!modal?.modalType) return null;

  const onCancel = () => closeModal();

  const onSubmitFn = (...params: unknown[]) => {
    modal.modalOnSubmit(...params);
    onCancel();
  };

  const SpecificModal: React.ElementType = MODAL_COMPONENTS[modal.modalType];

  // Remove <Portal> when all modals use Design System Modals
  return (
    <Portal>
      <SpecificModal
        {...modal.modalProps}
        onSubmit={onSubmitFn}
        onCancel={onCancel}
      />
    </Portal>
  );
};

export default ModalContainer;
