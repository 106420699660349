import {
  Box,
  Button,
  Checkbox,
  ModalBase,
} from '@bynder/design-system';
import PrimaryButton from '../shared/PrimaryButton';
import { useFetchGuideById } from '../../queries/guides';
import { Translate, useTranslate } from '@bynder/localization';
import { useHomepageMutations } from '../../mutations/homepage';
import { usePageMutations } from '../../mutations/pages';
import { useState } from 'react';

interface HomepageModalProps {
  onCancel: () => void;
  guideId: string;
}

const UnsetHomepageModal = ({ onCancel, guideId }: HomepageModalProps) => {
  const { translate } = useTranslate();
  const { unsetHomepage } = useHomepageMutations();
  const { data: guide } = useFetchGuideById(guideId);
  const { unpublishPage } = usePageMutations();
  const [keepPublished, setKeepPublished] = useState(true);

  const handleUnsetHomepage = () => {
    if (!keepPublished) {
      unpublishPage.mutate();
    }

    unsetHomepage.mutate({ guideId, guideVersion: guide?.version });
  };

  return (
    <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel}>
      <ModalBase.Header title={translate('UNSET_HOMEPAGE_MODAL.HEADER_TEXT')} />
      <ModalBase.Content>
        <Translate id="UNSET_HOMEPAGE_MODAL.CONTENT_TEXT" />
        <Box marginTop="5">
          <Checkbox
            isChecked={keepPublished}
            onChange={() => setKeepPublished(!keepPublished)}
          >
            <Translate id="UNSET_HOMEPAGE_MODAL.INFO_TEXT" />
          </Checkbox>
        </Box>
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <PrimaryButton
            data-testid="submitButton"
            onClick={handleUnsetHomepage}
          >
            <Translate id="UNSET_HOMEPAGE_MODAL.BUTTON_TEXT" />
          </PrimaryButton>
        }
        actionSecondary={
          <Button
            variant="secondary"
            onClick={onCancel}
            data-testid="cancelButton"
          >
            <Translate id="UNSET_HOMEPAGE_MODAL.CANCEL_TEXT" />
          </Button>
        }
      />
    </ModalBase>
  );
};

export default UnsetHomepageModal;
