import { useMutation } from '@tanstack/react-query';
import { produce } from 'immer';
import { findIndex, remove } from 'lodash';
import { useHistory } from 'react-router-dom';
import { ROUTES, queryClient } from '../constants/app';
import { track } from '../helpers/analytics';
import {
  addPageToChapter,
  addSectionTemplate,
  deletePageFromChapter,
  editBackgroundColorInPageHeader,
  editChapterTitleVisibility,
  editHeightInPageHeader,
  editPageHeaderVisibility,
  editPageTitle,
  editPageTitleVisibility,
  editTableOfContents,
  editTextColorInPageHeader,
  movePage,
  movePageToGuideApi,
  placeAssetInPageHeader,
  publishPage,
  unpublishPage,
} from '../helpers/api/guidelinesApi';
import { retrieveAssetInfoApi } from '../helpers/api/uploadApi';
import { getNow } from '../helpers/datetime';
import { GUIDE_KEYS } from '../queries/guides';
import {
  Cover,
  Guide,
  PageHeaderOptions,
  PageStates,
  Page,
  EntityVisibility,
} from '../types';
import { PageHeaderHeights } from '../types/headerHeights';
import { handleMutationSuccess, updateGuideVersion } from './helpers';
import { useGuideStore } from '../stores/guideStore';
import { generateUUID } from '../helpers/utils';
import { useModalStore } from '../stores/modalStore';
import { GuideTemplateBuilder } from '../constants/templates/guideTemplateBuilder';
import { TemplatesEnum } from '../constants/templates/constants';

interface MovePageOptions {
  legacyActiveGuideId: string;
  legacyActiveGuideVersion: number;
  legacyActiveChapterId: string;
  legacyActivePageId: string;
  toGuideId: string;
  toGuideVersion: number;
  toChapterId: string;
  toPageId: string;
  guideTitle: string;
}

export const usePageMutations = () => {
  const history = useHistory();
  const activeGuideId = useGuideStore(s => s.activeGuideId);
  const activePageId = useGuideStore(s => s.activePageId);
  const activeChapterId = useGuideStore(s => s.activeChapterId);
  const activeGuideVersion = useGuideStore(s => s.activeGuideVersion);
  const newPageId = generateUUID();
  const closeModal = useModalStore.use.closeModal();

  return {
    addPage: useMutation(
      (options: { pageTitle: string; chapterId: string; newPageId: string }) =>
        addPageToChapter(
          activeGuideId,
          options.chapterId,
          options.newPageId,
          options.pageTitle,
          activeGuideVersion,
        ),
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyAddPageToGuide(
            activeGuideId,
            variables.chapterId,
            variables.newPageId,
            variables.pageTitle,
          );
          closeModal();
        },
        onSuccess: data => {
          handleMutationSuccess('Page Added', data, {
            entityId: activeGuideId,
          });

          // TODO wait for new page to be created in sockets before allowing access
        },
      },
    ),
    addGuidePageTemplate: useMutation(
      (mutationOptions: {
        pageTitle: string;
        pageTemplate: TemplatesEnum;
        chapterId: string;
      }) =>
        addSectionTemplate(
          activeGuideId,
          GuideTemplateBuilder(
            activeGuideId,
            mutationOptions.chapterId,
            generateUUID(),
            generateUUID(),
            activeGuideVersion,
            mutationOptions.pageTemplate,
            mutationOptions.pageTitle,
          ),
          activeGuideVersion,
        ),
      {
        onMutate: () => {
          closeModal();
        },
        onSuccess: data => {
          handleMutationSuccess('Guide Created', data, {
            entityId: activeGuideId,
            payload: {
              newPageId,
            },
          });
        },
      },
    ),
    editPageHeaderTextColor: useMutation(
      (options: { textColorId: string }) =>
        editTextColorInPageHeader(
          activeGuideId,
          activeChapterId,
          activePageId,
          options.textColorId,
          activeGuideVersion,
        ),
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyUpdatePageHeader(
            activeGuideId,
            activePageId,
            'textColorId',
            variables.textColorId,
          );
        },
        onSuccess: data => {
          handleMutationSuccess('Page Header Edited', data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    editPageHeaderBackgroundColor: useMutation(
      (options: { backgroundColorId: string }) =>
        editBackgroundColorInPageHeader(
          activeGuideId,
          activeChapterId,
          activePageId,
          options.backgroundColorId,
          activeGuideVersion,
        ),
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyUpdatePageHeader(
            activeGuideId,
            activePageId,
            'backgroundColor',
            variables.backgroundColorId,
          );
        },
        onSuccess: data => {
          handleMutationSuccess('Page Header Edited', data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    editPageHeaderHeight: useMutation(
      (options: { selectedPageHeaderHeight: PageHeaderHeights }) =>
        editHeightInPageHeader(
          activeGuideId,
          activeChapterId,
          activePageId,
          options.selectedPageHeaderHeight,
          activeGuideVersion,
        ),
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyUpdatePageHeader(
            activeGuideId,
            activePageId,
            'height',
            variables.selectedPageHeaderHeight,
          );
        },
        onSuccess: data => {
          handleMutationSuccess('Page Header Edited', data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    placeAssetInPageHeader: useMutation(
      (options: { cover: Cover }) =>
        placeAssetInPageHeader(
          activeGuideId,
          activeChapterId,
          activePageId,
          options.cover,
          activeGuideVersion,
        ),
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyUpdatePageHeader(
            activeGuideId,
            activePageId,
            'asset',
            variables.cover,
          );
        },
        onSuccess: data => {
          handleMutationSuccess('Page Header Edited', data, {
            entityId: activeGuideId,
          });
        },
      },
    ),

    uploadAssetInPageHeader: useMutation(
      (options: { mediaId: string }) => {
        return retrieveAssetInfoApi(options.mediaId);
      },
      {
        retry: true,
        onSuccess: data => {
          updateGuideVersion(activeGuideId);
          track('Media uploaded from page header');
          optimisticallyUpdatePageHeader(activeGuideId, activePageId, 'asset', {
            assetId: data.id,
            assetName: data.name,
            detailUrl: `/media/?mediaId=${data.id}`,
            imageUrl: data.original,
          });
          placeAssetInPageHeader(
            activeGuideId,
            activeChapterId,
            activePageId,
            {
              assetId: data.id,
              assetName: data.name,
              detailUrl: `/media/?mediaId=${data.id}`,
              imageUrl: data.original,
            },
            activeGuideVersion,
          );
        },
      },
    ),
    publishPage: useMutation(
      () =>
        publishPage(
          activeGuideId,
          activeChapterId,
          activePageId,
          activeGuideVersion,
        ),
      {
        onMutate: () => {
          updateGuideVersion(activeGuideId);
          optimisticallyUpdatePage(
            activeGuideId,
            activeChapterId,
            activePageId,
            'state',
            PageStates.PUBLISHED,
          );
        },
        onSuccess: data => {
          handleMutationSuccess('Page Published', data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    unpublishPage: useMutation(
      () =>
        unpublishPage(
          activeGuideId,
          activeChapterId,
          activePageId,
          activeGuideVersion,
        ),
      {
        onMutate: () => {
          updateGuideVersion(activeGuideId);
          optimisticallyUpdatePage(
            activeGuideId,
            activeChapterId,
            activePageId,
            'state',
            PageStates.DRAFT,
          );
        },
        onSuccess: data => {
          handleMutationSuccess('Page Unpublished', data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    editPageTitle: useMutation(
      (options: { newTitle: string; pageId: string }) =>
        editPageTitle(
          activeGuideId,
          activeChapterId,
          options.pageId,
          options.newTitle,
          activeGuideVersion,
        ),

      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyUpdatePage(
            activeGuideId,
            activeChapterId,
            variables.pageId,
            'title',
            variables.newTitle,
          );
        },
        onSuccess: data => {
          handleMutationSuccess('Page Edited', data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    deletePage: useMutation(
      (options?: { pageId: string }) =>
        deletePageFromChapter(
          activeGuideId,
          activeChapterId,
          options.pageId ? options.pageId : activePageId,
          activeGuideVersion,
        ),
      {
        onMutate: options => {
          updateGuideVersion(activeGuideId);
          optimisticallyDeletePage(
            activeGuideId,
            activeChapterId,
            options.pageId ? options.pageId : activePageId,
          );
        },
        onSuccess: data => {
          handleMutationSuccess('Page Deleted', data, {
            entityId: activeGuideId,
          });
          history.push(ROUTES.GUIDE(activeGuideId));
        },
      },
    ),
    movePageToGuide: useMutation(
      (options: MovePageOptions) => {
        const {
          toGuideId,
          toGuideVersion,
          toChapterId,
          toPageId,
          // When deploying to production, we will be able to obtain these values from the context
          legacyActiveGuideId,
          legacyActivePageId,
          legacyActiveGuideVersion,
          legacyActiveChapterId,
        } = options;

        return movePageToGuideApi(toGuideId, toGuideVersion, {
          deletePage: false,
          from: {
            guideId: legacyActiveGuideId,
            guideVersion: legacyActiveGuideVersion,
            chapterId: legacyActiveChapterId,
            pageId: legacyActivePageId,
          },
          to: {
            chapterId: toChapterId,
            pageId: toPageId,
          },
        });
      },
      {
        onSuccess: (data, variables) => {
          handleMutationSuccess('Page Duplicated', data, {
            entityId: activeGuideId,
            queryPath: GUIDE_KEYS.GUIDE_BY_ID,
            payload: {
              toPageId: variables.toPageId,
              guideTitle: variables.guideTitle,
            },
          });
        },
      },
    ),
    editTableOfContentsVisibility: useMutation(
      (options: { isVisibleInGuide: boolean; isVisibleInPage: boolean }) =>
        editTableOfContents(
          activeGuideId,
          activeChapterId,
          activePageId,
          options.isVisibleInPage,
          options.isVisibleInGuide,
          activeGuideVersion,
        ),
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyUpdateTableOfContents(
            activeGuideId,
            activeChapterId,
            activePageId,
            variables.isVisibleInPage,
            variables.isVisibleInGuide,
          );
        },
        onSuccess: data => {
          handleMutationSuccess('Table of contents edited', data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    editPageTitleVisibility: useMutation(
      (options: { isVisible: boolean }) =>
        editPageTitleVisibility(
          activeGuideId,
          activeChapterId,
          activePageId,
          options.isVisible,
          activeGuideVersion,
        ),
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyUpdatePageHeader(
            activeGuideId,
            activePageId,
            'isTitleVisible',
            variables.isVisible,
          );
        },
        onSuccess: data => {
          handleMutationSuccess('Page title visibility changed', data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    editChapterTitleVisibility: useMutation(
      (options: { isVisible: boolean }) =>
        editChapterTitleVisibility(
          activeGuideId,
          activeChapterId,
          activePageId,
          options.isVisible,
          activeGuideVersion,
        ),
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyUpdatePageHeader(
            activeGuideId,
            activePageId,
            'isSubtitleVisible',
            variables.isVisible,
          );
        },
        onSuccess: data => {
          handleMutationSuccess('Chapter title visibility changed', data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    reorderSidebarPage: useMutation(
      (options: {
        pageId: string;
        fromChapterId: string;
        fromPosition: number;
        toChapterId: string;
        toPosition: number;
      }) =>
        movePage(
          activeGuideId,
          options.pageId,
          { chapterId: options.fromChapterId },
          { chapterId: options.toChapterId, position: options.toPosition },
          activeGuideVersion,
        ),
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyReorderPages(
            activeGuideId,
            variables.fromChapterId,
            variables.fromPosition,
            variables.toChapterId,
            variables.toPosition,
          );
        },
        onSuccess: data => {
          handleMutationSuccess('Page reordered', data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    editPageHeaderVisibility: useMutation(
      (options: { isVisible: boolean }) =>
        editPageHeaderVisibility(
          activeGuideId,
          activeChapterId,
          activePageId,
          options.isVisible,
          activeGuideVersion,
        ),
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyUpdatePageHeader(
            activeGuideId,
            activePageId,
            'isVisible',
            variables.isVisible,
          );
        },
        onSuccess: data => {
          handleMutationSuccess('Page header visibility changed', data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
  };
};

const optimisticallyAddPageToGuide = (
  activeGuideId: string,
  activeChapterId: string,
  activePageId: string,
  pageTitle: string,
) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.GUIDE_BY_ID, activeGuideId],
    (old: Guide) => {
      if (old) {
        const chapterIndex = findIndex(
          old.chapters,
          chapter => chapter.id === activeChapterId,
        );

        return produce(old, draft => {
          draft.chapters[chapterIndex].pages.push(
            getNewPage(activePageId, pageTitle),
          );
        });
      }

      return old;
    },
  );
};

const optimisticallyDeletePage = (
  activeGuideId: string,
  activeChapterId: string,
  activePageId: string,
) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.GUIDE_BY_ID, activeGuideId],
    (old: Guide) => {
      const chapterIndex = findIndex(
        old.chapters,
        chapter => chapter.id === activeChapterId,
      );

      if (old) {
        return produce(old, draft => {
          remove(
            draft.chapters[chapterIndex].pages,
            page => page.id === activePageId,
          );
        });
      }

      return old;
    },
  );
};

const optimisticallyUpdateTableOfContents = (
  activeGuideId: string,
  activeChapterId: string,
  activePageId: string,
  visibleInPage: boolean,
  visibleInGuide: boolean,
) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, activeGuideId, activePageId],
    (old: Page) => {
      if (old) {
        return produce(old, draft => {
          draft.tableOfContents.isVisibleInGuide = visibleInGuide;
          draft.tableOfContents.isVisibleInPage = visibleInPage;
        });
      }
    },
  );
  queryClient.setQueryData(
    [GUIDE_KEYS.GUIDE_BY_ID, activeGuideId],
    (old: Guide) => {
      if (old) {
        const chapterIndex = findIndex(
          old.chapters,
          chapter => chapter.id === activeChapterId,
        );
        const pageIndex = findIndex(
          old.chapters[chapterIndex].pages,
          page => page.id === activePageId,
        );

        return produce(old, draft => {
          draft.chapters[chapterIndex].pages[
            pageIndex
          ].tableOfContents.isVisibleInGuide = visibleInGuide;
          draft.chapters[chapterIndex].pages[
            pageIndex
          ].tableOfContents.isVisibleInPage = visibleInPage;
        });
      }

      return old;
    },
  );
};

const optimisticallyUpdatePage = <T extends keyof Page>(
  activeGuideId: string,
  activeChapterId: string,
  activePageId: string,
  key: T,
  value: Page[T],
) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.GUIDE_BY_ID, activeGuideId],
    (old: Guide) => {
      const chapterIndex = findIndex(
        old.chapters,
        chapter => chapter.id === activeChapterId,
      );
      const pageIndex = findIndex(
        old.chapters[chapterIndex].pages,
        page => page.id === activePageId,
      );
      if (old) {
        return produce(old, draft => {
          draft.chapters[chapterIndex].pages[pageIndex][key] = value;
        });
      }

      return old;
    },
  );
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, activeGuideId, activePageId],
    (old: Page) => {
      if (old) {
        return produce(old, draft => {
          draft[key] = value;
        });
      }

      return old;
    },
  );
};

const optimisticallyUpdatePageHeader = <T extends keyof PageHeaderOptions>(
  activeGuideId: string,
  activePageId: string,
  key: T,
  value: PageHeaderOptions[T],
) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, activeGuideId, activePageId],
    (old: Page) => {
      if (old) {
        return produce(old, draft => {
          draft.header = { ...old.header, [key]: value };
        });
      }
    },
  );
};

const getNewPage = (id: string, title: string): Page => ({
  id,
  title,
  sections: [],
  isFresh: true,
  creationTime: getNow(),
  lastModifiedTime: getNow(),
  permissions: [],
  visibility: EntityVisibility.SPECIFIC,
  widgetCount: 0,
  canEdit: true,
  canView: true,
  state: PageStates.DRAFT,
  tableOfContents: {
    entries: [],
    isVisibleInGuide: false,
    isVisibleInPage: false,
  },
});

const optimisticallyReorderPages = (
  guideId: string,
  fromChapterId: string,
  fromPosition: number,
  toChapterId: string,
  toPosition: number,
) => {
  queryClient.setQueryData([GUIDE_KEYS.GUIDE_BY_ID, guideId], (old: Guide) => {
    if (old) {
      const fromChapterIndex = findIndex(
        old.chapters,
        chapter => chapter.id === fromChapterId,
      );

      const toChapterIndex = findIndex(
        old.chapters,
        chapter => chapter.id === toChapterId,
      );
      const page = { ...old.chapters[fromChapterIndex].pages[fromPosition] };

      return produce(old, draft => {
        draft.chapters[fromChapterIndex].pages.splice(fromPosition, 1);
        draft.chapters[toChapterIndex].pages.splice(toPosition, 0, page);
      });
    }

    return old;
  });
};
